import './product-tile.scss'
import { Image } from '@/theme/components/Image'
import Form from '../Form'
import useProductContext, {
   ProductContextProvider,
} from '@/theme/route/ProductPage/useProductContext'
import {
   isProductGift,
   isProductBundle,
   isProductSimple,
} from '@/services/helpers/isProductType'
import ConfigurableProductV2 from '../ConfigurableProductV2'
import AddToCartButton from '../ProductMain/AddToCartButton'
import Link from '@/core/Link'
import useAuthContext from '../Contexts/AuthContext'
import { removeProductsFromWishlist } from 'src/graphql/mutations/customermutation'
import { useEffect, useState } from 'react'
import { useAppContext } from '@/core/App/AppContext'
import { useGraphQLMutation } from '@/theme/lib/useGraphQLQuery'
import { gql } from 'graphql-request'
import Button from '../Button'
import we from 'src/services/webengage'
import { ProductRatingNumber } from '../ConsolidatedReview'
import { calGlowPoints } from '../ProductMain'
import { Currency } from '@/services/helpers/currency'

export const ProductTileLoader = () => {
   return (
      <div className="product-tile-placeholder">
         <div className="image-placeholder skeleton-box"></div>
         <div className="name-placeholder skeleton-box"></div>
         <div className="price-placeholder skeleton-box"></div>
         <div className="tocart-placeholder skeleton-box"></div>
      </div>
   )
}

export const currencyFormat = (value = 0) => {
   const numFormatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
   })
   return numFormatter.format(value)
}

export const ProductPrice = (props) => {
   const [{ cartItem, price, amount_off, list_price }] = useProductContext()
   let finalPrice: any = price * cartItem.quantity
   if (finalPrice) {
      finalPrice = Currency({ money: Number(finalPrice) })
   }
   let finalListPrice: any = list_price * cartItem.quantity
   if (finalListPrice) {
      finalListPrice = Currency({ money: Number(finalListPrice) })
   }
   return (
      <div className='MRP-para-new'>
         <div className={`d-inline-flex gap-2 ${props.className ?? ''}`}>
            <span className="price">{`${finalPrice}/-`}</span>
            {amount_off > 0 ? (
               <del className="price old-price">{finalListPrice}/-</del>
            ) : null}
         </div>
         <p className='price--bottom-mrp'>MRP Inclusive of all taxes</p>
      </div>
   )
}

export const ProductHealthPoints = ({ glowPoints = 0 }) => {
   const [{ cartItem, price }] = useProductContext()
   let finalPrice: any = price * cartItem.quantity
   if (finalPrice) {
      finalPrice = Currency({ money: Number(finalPrice) })
   }
   // let finalListPrice: any = list_price * cartItem.quantity
   // if (finalListPrice) {
   //   finalListPrice = currencyFormat(Number(finalListPrice))
   // }
   console.log({ finalPrice }, calGlowPoints(finalPrice, glowPoints))
   return (
      <div className="text-center">
         Earn {Currency({ money: calGlowPoints(finalPrice, glowPoints) })} as Health
         points.
      </div>
   )
}

const WishlistButton = ({ wishlist_id }) => {
   const [, { getCartItem }] = useProductContext()
   const [, , { addToWishlist }] = useAuthContext()
   return (
      <>
         {wishlist_id ? (
            <svg
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 24 24"
               fill="currentColor"
               id="icon-heart"
               className="w-6 h-6 pointer towishlist addedToWishlist"
               onClick={(e) => {
                  e.preventDefault()
                  addToWishlist(getCartItem())
               }}
            >
               <path d="m11.645 20.91-.007-.003-.022-.012a15.247 15.247 0 0 1-.383-.218 25.18 25.18 0 0 1-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0 1 12 5.052 5.5 5.5 0 0 1 16.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 0 1-4.244 3.17 15.247 15.247 0 0 1-.383.219l-.022.012-.007.004-.003.001a.752.752 0 0 1-.704 0l-.003-.001Z" />
            </svg>
         ) : (
            <svg
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 24 24"
               strokeWidth={1.5}
               stroke="currentColor"
               className="towishlist w-6 h-6 pointer"
               id="icon-heart"
               onClick={(e) => {
                  e.preventDefault()
                  addToWishlist(getCartItem())
               }}
            >
               <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
               />
            </svg>
         )}
      </>
   )
}

const ProductTile = (props) => {
   const {
      name,
      image,
      discount,
      sku,
      size,
      pack_size = [],
      url_key,
      url_prefix,
      __typename,
      short_description,
      wishlist_id,
      wishlist_item_id,
      productCardVariant = 'vertical',
   } = props
   const [,] = useAuthContext()
   const [{ mitter }] = useAppContext()
   const [isGrid, setIsGrid] = useState(false)
   const isGiftProduct = isProductGift(props)
   const [{ data: removeProduct }, mutate] = useGraphQLMutation(
      gql`
      ${removeProductsFromWishlist}
    `,
      null,
      { authorized: true }
   )

   if (removeProduct) {
      we.event('Removed from wishlist', {
         'Product Id': `${props?.id}`,
         'Product Name': `${props?.name}`,

         'Category Id': ``,
         'Category Name': `${props?.categories?.map((i) => i?.name)}`,
         'SubCategory Name': '',
         'Sub Category Id': '',
         Brand: 'Moha',
         Manufacturer: 'Moha',
         'Retail Price': props?.price_range?.minimum_price?.final_price?.value,
         discount: props?.price_range?.minimum_price?.discount?.amount_off,
         Price:
            props?.price_range?.minimum_price?.final_price?.value +
            props?.price_range?.minimum_price?.discount?.amount_off,
         Currency: `${props?.price_range?.minimum_price?.final_price?.currency}`,
         Size: '',
         Color: '',
         Image: `${props?.image?.url}`,
      })
      window.location.reload()
   }

   useEffect(() => {
      mitter.on('isGrid', setIsGrid)
   }, [])
   if (productCardVariant == 'horizontal') {
      return (
         <ProductContextProvider product={props}>
            <div
               className={`product-item-info product-details product-card--${productCardVariant}`}
            >
               <div className="product-details-inner">
                  <Link
                     href={`/${url_key}${url_prefix || ''}`}
                     className="product-item-image"
                  >
                     <Image
                        aspectRatio={'1/1'}
                        key={image.url}
                        url={image.url}
                        label={image.label}
                        loading={'lazy'}
                     />

                     {/* <Image {...image} dimWidth={400} /> */}
                  </Link>
                  {discount > 0 && <span className="sale-label">{discount}%</span>}
                  <div className="product-item-details">
                     <div className="product-reviews-summary">
                        <ProductRatingNumber sku={sku} />
                     </div>
                     <Link
                        title={name}
                        href={`/${url_key}${url_prefix || ''}`}
                        className="product-item-name data-title"
                     >
                        <span>{name}</span>
                     </Link>

                     {isGiftProduct && (
                        <Link href={`/${url_key}${url_prefix || ''}`}>
                           <Button>Select Amount</Button>
                        </Link>
                     )}
                     <ConfigurableProductV2 skipLabel />
                     {isProductSimple({ __typename }) && pack_size ? (
                        <fieldset className="fieldset">
                           <Form.Field label="">
                              <div className="control">
                                 <div className="input">{pack_size}</div>
                              </div>
                           </Form.Field>
                        </fieldset>
                     ) : null}
                     {isProductSimple({ __typename }) && size ? (
                        <fieldset className="fieldset">
                           <Form.Field label="">
                              <div className="control">
                                 <div className="input">{size}</div>
                              </div>
                           </Form.Field>
                        </fieldset>
                     ) : null}
                     {isProductBundle({ __typename }) ? (
                        <fieldset className="fieldset">
                           <Form.Field label="">
                              <div className="control">
                                 <div className="input">{pack_size}</div>
                              </div>
                           </Form.Field>
                        </fieldset>
                     ) : null}
                     <div className="box-tocart">
                        <ProductPrice />
                        <AddToCartButton
                           className="btn-primary product-btn"
                           buttonText="Add To Cart"
                        />
                     </div>
                  </div>
               </div>
            </div>
         </ProductContextProvider>
      )
   }
   return (
      <ProductContextProvider product={props}>
         <li className="proudct-grid">
            <div
               className={`product-item-info product-details ${productCardVariant}`}
            >
               <div className="product-details-inner">
                  <Link
                     href={`/${url_key}${url_prefix || ''}`}
                     className="product-item-image"
                  >
                     <Image
                        aspectRatio={'1/1'}
                        key={image.url}
                        url={image.url}
                        label={image.label}
                        loading={'lazy'}
                     />

                     {/* <Image {...image} dimWidth={400} /> */}
                  </Link>
                  {discount > 0 && <span className="sale-label">{discount}%</span>}
                  <WishlistButton wishlist_id={wishlist_id} />
                  <div className="product-reviews-summary">
                     <ProductRatingNumber sku={sku} />
                  </div>
                  <div className="product-item-details">
                     <Link
                        title={name}
                        href={`/${url_key}${url_prefix || ''}`}
                        className="product-item-name data-title"
                     >
                        <span>{name}</span>
                     </Link>

                     {isGiftProduct && (
                        <Link href={`/${url_key}${url_prefix || ''}`}>
                           <Button>Select Amount</Button>
                        </Link>
                     )}
                  </div>

                  <ConfigurableProductV2 skipLabel />
                  {isProductSimple({ __typename }) && pack_size ? (
                     <fieldset className="fieldset">
                        <Form.Field label="">
                           <div className="control">
                              <div className="input">{pack_size}</div>
                           </div>
                        </Form.Field>
                     </fieldset>
                  ) : null}
                  {isProductSimple({ __typename }) && size ? (
                     <fieldset className="fieldset">
                        <Form.Field label="">
                           <div className="control">
                              <div className="input">{size}</div>
                           </div>
                        </Form.Field>
                     </fieldset>
                  ) : null}
                  {isProductBundle({ __typename }) ? (
                     <fieldset className="fieldset">
                        <Form.Field label="">
                           <div className="control">
                              <div className="input">{pack_size}</div>
                           </div>
                        </Form.Field>
                     </fieldset>
                  ) : null}
                  {wishlist_id && (
                     <div
                        className="pointer"
                        onClick={() => {
                           mutate({
                              wishlistId: wishlist_id,
                              wishlistItemsIds: [wishlist_item_id],
                           })
                        }}
                     >
                        Remove from wishlist
                     </div>
                  )}
               </div>
               <div className="box-tocart">
                  <ProductPrice />
                  <AddToCartButton
                     className="btn-primary product-btn"
                     buttonText="Add To Cart"
                  />
               </div>
            </div>
            {isGrid && (
               <div
                  className="grid-items-short-description"
                  dangerouslySetInnerHTML={{ __html: short_description.html }}
               ></div>
            )}
         </li>
      </ProductContextProvider>
   )
}

export default ProductTile
