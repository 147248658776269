import { Fragment, useEffect } from 'react'
import useGraphQLQuery, { useGetGraphQLQuery } from '../lib/useGraphQLQuery'
import ErrorBoundary from '@/core/ErrorBoundary'
import { useInView } from 'react-intersection-observer'

interface GQLQueryAsync {
  query: any
  variables?: any
  children?: () => {} | any
  onLoad?: () => {} | any
}

export default function GQLQueryAsync({
  query,
  variables = {},
  children,
  onLoad = null,
  method = 'GET',
  ...props
}) {
  const [{ data, loading: isLoading, error }, request, { reset }] =
    useGraphQLQuery(query, variables, {
      immediate: true,
      method,
      authorized: false,
    })
  useEffect(() => {
    // request(variables)
  }, [variables, request])
  console.log('gql', { error })
  useEffect(() => {
    if (data) {
      onLoad && onLoad(data)
    }
  }, [data])
  if (isLoading) {
    return <div>{'Loading...'}</div>
  }

  return (
    <ErrorBoundary>
      <div {...props}>{children && children(data)}</div>
    </ErrorBoundary>
  )
}
