import React from 'react'
import { useState } from 'react'
import useCartContext from '../CartWrapper/useCartContext'
import { Button, CloseButton, Offcanvas } from 'react-bootstrap'
import { CouponCode } from '../CouponCode'
import GQLQueryAsync from '../GQLQueryAsync'
import { getCoupon } from 'src/graphql/queries/getCoupon'
import { CouponCodePill } from '../CouponCode/CouponCodePill'

export default function CouponForm() {
  const [cart, dispatch, { applyCoupon, removeCoupon, loadingCart }] =
    useCartContext()
  const [couponCode, setCouponCode] = useState('')
  const [openList, setOpenList] = useState(false)

  return (
    <>
      <div className="coupon-div ">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="mb-0">Apply Coupon</h5>
          <div className="input-align input-group">
            <input
              placeholder=""
              aria-label="coupon code"
              aria-describedby="basic-addon2"
              className="form-control"
              value={couponCode}
              onChange={({ target }) => setCouponCode(target.value)}
            />
            <Button
              onClick={(event) => {
                console.log('couponCode', couponCode)
                applyCoupon(couponCode)
              }}
              className="btn btn-primary coupon-btn"
            >
              Apply code
            </Button>
          </div>
        </div>
        <div className="text-center d-flex align-items-center gap-4">
          <div>Available coupons:</div>
          <GQLQueryAsync
            query={getCoupon}
            className="d-flex gap-3 align-items-center"
          >
            {(response) => (
              <>
                {response?.getCouponslist?.map((ccode, codeIndex) => (
                  <CouponCodePill
                    key={codeIndex}
                    title={ccode.title}
                    description={ccode.description}
                    code={ccode.coupon_code}
                    // close={close}
                    // setSuccessModal={setSuccessModal}
                  />
                ))}
              </>
            )}
          </GQLQueryAsync>
        </div>
        <p className="extra--cart--note">
          No Coupon code is valid on Vedistry Health Mantra plans.
        </p>
      </div>
      <Offcanvas show={openList} placement="end" className="cart-popup-sidebar">
        <Offcanvas.Header>
          <Offcanvas.Title className="text-center position-relative">
            <CloseButton
              onClick={() => setOpenList((prev) => !prev)}
              className="closebtn position-absolute start-0"
            />
            <span
              className="offcanvas-title-text"
              onClick={() => setOpenList((prev) => !prev)}
            >
              Available coupons
            </span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <CouponCode />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}
